import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input, OnChanges,
    OnDestroy,
    Output,
    Renderer2, SimpleChanges,
    ViewChild
} from '@angular/core';
import {FileUploadService} from "../../services/file-upload.service";
import {HttpHeaders, HttpResponse} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {SessionService} from "../../../session/session.service";
import {InterfaceProviderService} from "../../../basic-entity-back/services/interface-provider.service";
import {BehaviorSubject, Subject, Subscription} from "rxjs";
import {debounceTime, finalize} from "rxjs/operators";
import {ObjViewerService} from "../../../services/obj-viewer.service";
import {Pedido} from "../../../model/pedidos/pedido.model";

@Component({
    selector: 'be-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements AfterViewInit, OnDestroy, OnChanges {

    progress = 0;
    progressType = '';
    message = '';
    isArray = false;

    private removeSubscription = new BehaviorSubject<boolean>(false);
    private subscriptions: Subscription[] = [];

    @ViewChild('objViewer') objViewer: ElementRef;
    @ViewChild('lineaArchivo') lineaArchivo: ElementRef;
    @Input() buttonName = '';
    @Input() listaArchivos: any[] = [];
    @Output() public fileUploadedEmmiter = new EventEmitter<any[]>();
    @Output() public fileRemovedEmitter = new EventEmitter<any>();
    @Output() public seleccionEmmiter = new EventEmitter<any>();
    @Input() mostrarVisor = true;
    @Input() seleccionarPrimero = true;
    @Input() archivoSeleccionado: any = null;
    @Input() archivoSiguiente: any = null;
    @Input() disableButton = false;
    @Input() modelType = null;
    @Input() parentModelType = null;
    @Input() actualizarVisor = new BehaviorSubject<boolean>(false);
    private clicked = new Subject<any>();
    private urlFile = environment.uploaderUrl;
    seleccionando = false;

    constructor(private uploadService: FileUploadService, private sessionService: SessionService, private interfaceProvider: InterfaceProviderService,
                private readonly _objViewerService: ObjViewerService, private readonly _renderer: Renderer2) {

        let params = new HttpHeaders();
        params = params.append('Authorization', 'Bearer ' + sessionService.token.token);
        this.uploadService.setBaseUrl(this.urlFile);
        this.uploadService.setRequestHeaders(params);
        this.uploadService.progressCompressObserver.subscribe(percent => {
            this.progress = percent;
            this.progressType = 'Comprimido';
        });
        this.uploadService.progressUploadObserver.subscribe(percent => {
            this.progress = percent;
            this.progressType = 'Subido';
        });
        this.removeSubscription.subscribe(result => {
            if (result) {
                this.subscriptions.forEach(sus => sus.unsubscribe());
            }
        })
    }

    ngAfterViewInit(): void {
        if (this.mostrarVisor) {
            this._objViewerService.iniciarEscena(this._renderer, this.objViewer.nativeElement);
            setTimeout(() => {
                this.seleccionar(this.archivoSeleccionado)
            }, 500);

            this.actualizarVisor.subscribe(actualizar => {
                if (actualizar && !this._objViewerService.escenaIniciada()) {
                    setTimeout(() => {
                        this._objViewerService.iniciarEscena(this._renderer, this.objViewer.nativeElement);
                        this.seleccionar(this.archivoSeleccionado);
                    }, 500);
                } else if (this.archivoSeleccionado) {
                    const url = this.urlFile + '/' + this.archivoSeleccionado.id + '/descargar';
                    if (this.mostrarVisor) {
                        this._objViewerService.loadObj(url, this.archivoSeleccionado.nombreFichero);
                    }
                    this.seleccionEmmiter.next(this.archivoSeleccionado);
                }
            })
        }


        // Para evitar el doble click en el objeto
        const buttonClickedDebounced = this.clicked.pipe(debounceTime(350));
        buttonClickedDebounced.subscribe((archivo: any) => this.seleccionar(archivo));
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.disableButton) {
            this.disableButton = changes.disableButton.currentValue;
        }

        if (changes.archivoSeleccionado && (changes.archivoSeleccionado.previousValue !== changes.archivoSeleccionado.currentValue && changes.archivoSeleccionado.currentValue !== undefined)) {
            this.actualizarVisor.next(true);
        }

        if (changes.listaArchivos && changes.listaArchivos.currentValue && changes.listaArchivos.currentValue.length === 0 && this.objViewer) {
            this._objViewerService.limpiarObj();
        }

        if (changes.modelType && changes.modelType.currentValue && changes.modelType.currentValue !== changes.modelType.previousValue) {
            this.modelType = changes.modelType.currentValue;
            this.urlFile = this.modelType ? environment.apiUrl + '/' + this.interfaceProvider.interfaceForModel(this.modelType).endpoint : environment.uploaderUrl;
            this.uploadService.setBaseUrl(this.urlFile);
        }
    }

    ngOnDestroy(): void {
        // this._objViewerService.limpiarEscena();
        this.subscriptions.forEach(sus => sus.unsubscribe());
    }

    selectFile(event: any): void {
        this.seleccionando = true;
        if (event.target.files && event.target.files.length === 1) {
            const file: File = event.target.files[0];
            this.upload(file, this.parentModelType);
        } else if (event.target.files.length > 1) {
            const fileListAsArray = Array.from(event.target.files);
            fileListAsArray.forEach((ar: File) => this.upload(ar, this.parentModelType));
        }
    }

    upload(file: File, type: any): void {
        this.progress = 0;
        let files = [];
        this.message = "";
        if (file) {
            this.subscriptions.push(this.uploadService.upload(file, type).pipe(finalize(() => {
                this.listaArchivos.push(...files);
                this.seleccionar(this.listaArchivos[this.listaArchivos.length - 1]);
            })).subscribe(
                (event: any) => {
                    if (event instanceof HttpResponse) {
                        if (Array.isArray(event.body)) {
                            files = event.body.map(file => {
                                this.interfaceProvider.interfaceForModel(this.modelType).fromPostToModel(file)
                            });
                        } else {
                            files.push(this.interfaceProvider.interfaceForModel(this.modelType).fromPostToModel(event.body));
                        }
                        this.seleccionando = false;
                    }
                },
                (err: any) => {
                    this.progress = 0;
                    if (err.error && err.error.message) {
                        this.message = err.error.message;
                    } else {
                        this.message = 'Could not upload the file!';
                    }

                    this.seleccionando = false;
                }));
        }
    }


    dropEvent(event: DragEvent) {
        event.preventDefault();
        this.progress = 1;
        const items = event.dataTransfer.items;
        this.uploadService.proccessDropEventFiles(items).subscribe(file => this.upload(file, this.parentModelType));
    }

    onDragOver(event: DragEvent) {
        event.stopPropagation();
        event.preventDefault();
    }


    update(archivos: any[]) {
        this.listaArchivos.push(...archivos);
    }

    borrar(archivo: any, i) {
        this.uploadService.remove(archivo).subscribe(() => {
            this.listaArchivos.splice(i, 1);
            if (this.mostrarVisor) {
                this.seleccionar(this.listaArchivos[i]);
            }
        });
        this.fileRemovedEmitter.emit(archivo)
    }

    borrarTodos() {
        this.listaArchivos.forEach((archivo, index) => this.uploadService.remove(archivo).subscribe(() => {
            if (index === this.listaArchivos.length - 1) {
                this.listaArchivos = [];
                this.seleccionar(null);
            }
        }));
    }

    click(archivo?: any) {
        this.archivoSiguiente = archivo;
        this.clicked.next(archivo)
    }

    seleccionar(archivo?: any) {
        if (archivo && this.seleccionarPrimero) {
            this.archivoSeleccionado = archivo;
            const url = this.urlFile + '/' + archivo.id + '/descargar';
            if (this.mostrarVisor) {
                this._objViewerService.loadObj(url, archivo.nombreFichero);
            }
        } else {
            this.archivoSeleccionado = null;
        }

        this.fileUploadedEmmiter.next(this.listaArchivos)
        setTimeout(() => this.seleccionEmmiter.next(archivo), 300);
        this.archivoSiguiente = null;
    }

    nombreFichero(archivo: any) {
        return archivo ? archivo.nombreFichero.substr(14, archivo.nombreFichero.length) : '';
    }
}
