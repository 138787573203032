import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {TABLES} from "./tables";
import {InicioComponent} from "./pages/inicio/inicio.component";
import {ItemsComponent} from "./pages/admin/items/items.component";
import {ClienteEditionComponent} from "./pages/personas/clientes/cliente-edition/cliente-edition.component";
import {WelcomeComponent} from "./pages/inicio/welcome/welcome.component";
import {PasswordResetComponent} from "./session/password-reset/password-reset.component";
import {LoginGuard} from "./session/login.guard";
import {IncidenciasModule} from "./pages/incidencias/incidencias.module";


const routes: Routes = [
    ...TABLES,
    {
        path: 'inicio',
        component: InicioComponent,
        canActivate: [LoginGuard],
        data: {ifNotLogged: 'login', expectedRole: 'ROLE_USUARIO'}
    },
    {
        path: 'items',
        component: ItemsComponent,
        canActivate: [LoginGuard],
        data: {expectedRole: 'ROLE_USUARIO', title: 'Items'}
    },
    {
        path: 'datos',
        component: ClienteEditionComponent,
        canActivate: [LoginGuard],
        data: {ifNotLogged: 'login', expectedRole: 'ROLE_USUARIO'}
    },
    {
        path: 'session',
        loadChildren: () => import('./session/session.module').then(m => m.SessionModule),
        canActivate: [LoginGuard],
        data: {expectedRole: 'ROLE_USUARIO'}
    },
    {
        path: 'welcome',
        canActivate: [LoginGuard],
        data: {ifNotLogged: 'login', expectedRole: 'ROLE_USUARIO'},
        component: WelcomeComponent
    },
    {
        path: 'password-reset',
        component: PasswordResetComponent,
        canActivate: [LoginGuard],
        data: {ifNotLogged: 'login'},
    },
    {
        path: 'trabajos',
        loadChildren: () => import('./pages/trabajos/trabajos.module').then(m => m.TrabajosModule)
    },
    {
        path: 'pedidos',
        loadChildren: () => import('./pages/pedidos/pedidos.module').then(m => m.PedidosModule)
    },
    {
        path: 'solicitudes',
        loadChildren: () => import('./pages/solicitudes/solicitudes.module').then(m => m.SolicitudesModule)
    },
    {
        path: 'incidencias',
        loadChildren: () => import('./pages/incidencias/incidencias.module').then(m => m.IncidenciasModule)
    },
    {
        path: 'facturas',
        loadChildren: () => import('./pages/facturas/facturas.module').then(m => m.FacturasModule)
    },
    {
        path: '',
        redirectTo: 'inicio',
        pathMatch: 'full',
    }];

@NgModule({
    imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
